/*
 * Globals
 */

 @import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');

/* Aurelia */
@font-face {
  font-family: 'Aurelia';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/Aurelia.ttf') format('truetype'), 
       url('../fonts/Aurelia.otf') format('opentype');
}

.andSymbol {
  font-family: Aurelia;
  font-size: 3em;
  color: #B39D6C;
}

.andLogo {
  font-size: 2em;
  margin-right: -0.15em;
}

.andTitle {
  font-size: 3em;
  margin-right: -0.15em;
}

.btn-save:focus {
  background: #B39D6C;
}

h1 {
font-size: calc(1.6rem + 0.5vw);
}



.flowers::before {
  content: "";
  position: absolute;
  padding: 8rem;
  left: calc(50% - 8rem);
  margin-top: -13rem;
  z-index: -1;
  background: url('../images/flowers.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  transform: rotate(-45deg);
}

.lead {
  letter-spacing: 0.3em;
  font-size: calc(0.8rem + 0.4vw);
}

.date {
  color: #B39D6C;
  font-weight: bold;
}

.location {
  margin-bottom: 2em;
}

.btn-save {
  background: #B39D6C;
  margin: 0.5rem;
}

.btn-decline {
  margin: 0.5rem;
}

.btn-save:hover {
  background: #a18d62;
}

.logo {
  line-height: 0.8;
}

.map {
  width: calc(100vw - var(--scrollbar-width));
  position: relative;
  height: 600px;
  margin-left: -50vw;
  left: 25vw;
}

.info-header {
  margin-top: 2em;
}

a.card-text {
  color: #B39D6C;
  display: block;
}



/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}


/*
 * Base structure
 */

body {
  /* text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5); */
  /* box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5); */
  font-family: 'Cinzel', serif;
  --bs-bg-opacity: 1;
  /* background-color: rgba(228,219,212,var(--bs-bg-opacity))!important; */
  background-color: white !important;
}

h1, h2, h3, h4, h5 {
  font-family: 'Cinzel', serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  padding-left: 0.3rem !important;
}

h1 {
  text-transform: uppercase;
  letter-spacing: 1rem;
  padding-left: 1rem !important;
}

h3 {
  font-size: 2rem;
  letter-spacing: 1rem;
  padding-left: 1rem !important;
}

.cover-container {
  max-width: 72em;
  z-index: 990;
}

main {
  margin-top: 30vh; 
}

.nav-link {
  padding-left: 0.3rem!important;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

.modal-backdrop {
  z-index: 999;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  max-width: 50rem;
  margin: auto;
  flex-wrap: wrap;
}

#modalDeclineBox {
  margin-top: 30vh;
}

p {
  margin-bottom: 1.6rem;
}


/*
 * Header
 */

.nav-masthead .nav-link {
  color: rgba(6, 6, 6, 0.5);
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #B39D6C;
  border-bottom-color: #B39D6C;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

@media (max-width: 414px) {
  h1 {
    letter-spacing: 0.5rem;
  }

  .andTitle {
    font-size: 2em;
  }

  .lead {
    letter-spacing: 0.2rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  /* vertical-align: -.125em; */
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
